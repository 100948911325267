import React, { useEffect, Fragment } from "react"

import { scrollToElement } from "../../../utility/utils";
import CTAInvestments from "../general/ctaInvestments";
import FAQList from "../general/faqsList";
import BelowFoldCards from "../general/belowFoldCards";

import SalaryLoanIllustration from "../../../../assets/img/salary-loan-illustration.inline.svg";
import InterestRateIllustration from "../../../../assets/img/interest-rate-illustration.inline.svg";
import QuickLoanIllustration from "../../../../assets/img/kuda-quick-loan-illustration.inline.svg";
import RepayLoanIllustration from '../../../../assets/img/repay-loan-illustration.inline.svg'

import PercentageIcon from "../../../../assets/img/percentage-blue.inline.svg";
import HandCashIcon from "../../../../assets/img/kuda-hand-cash.inline.svg";
import KudaAppIcon from "../../../../assets/img/kuda-app-miniIcon.inline.svg";

import ImageTextLeft from "../general/imageTextLeft";
import ImageTextRight from "../general/imageTextRight";
import BadgesEntryFold from "../general/badgesEntryFold";
import MoreForYouComponent from "../general/more-for-you";

import OverdraftIcon from '../../../../assets/img/more-for-you/kuda-overdrafts-icon.inline.svg'
import BorrowIcon from '../../../../assets/img/more-for-you/kuda-borrow-icon.inline.svg'
import KudaSaveIcon from '../../../../assets/img/more-for-you/kuda-save-icon.inline.svg'

const entryContent = {
  title: "Get a collateral-free salary loan at a competitive rate through Kuda!",
  subtitle: "Refer us to your employer to get access to a Kuda Salary Loan with quick loan application, approval and disbursement.",
  illustration: <SalaryLoanIllustration />,
  url: "/en-ng/salary-loan-retail-form/",
  name: "Refer Your Employer",
}

const interestRate = {
  title: "Get a collateral-free salary loan at a competitive interest rate easily.",
  subtitle:
    "We’ve set our interest rates carefully to support your financial wellbeing and make repayment convenient.",
  url: "/en-ng/salary-loan-retail-form/",
  name: "Refer Your Employer",
  illustration: <InterestRateIllustration />,
}


const quickLoan = {
  title: "Meet your urgent needs through our quick loan process.",
  subtitle: "Our automated loan approval and disbursement process will help you meet your needs in the shortest time possible.",
  url: "/en-ng/salary-loan-retail-form/",
  name: "Refer Your Employer",
  illustration: <QuickLoanIllustration />,
}

const repayLoan = {
  title: "Repay your loan easily on the Kuda app.",
  subtitle: "With scheduled repayment alerts, loan balance information and more, the Kuda app makes managing your loan and repaying it on time simple.",
  url: "/en-ng/salary-loan-retail-form/",
  name: "Refer Your Employer",
  illustration: <RepayLoanIllustration />,
}
const kudaTopInvestments = [
  {
    icon: <PercentageIcon />,
    text: "Get a salary loan at competitive interest rates easily.",
  },
  {
    icon: <HandCashIcon />,
    text: "Meet urgent money needs through our quick loan process.",
  },
  {
    icon: <KudaAppIcon />,
    text: "Repay your loan in instalments on the Kuda app.",
  },

]

const questions = [
  {
    heading: "What is a salary loan?",
    list: (
      <span className="flex flex-column">
        <span className="faq-description f-16"> A salary loan is a short-term loan that employees can take based on their annual salary. A salary loan usually has a fixed interest rate, doesn’t require collateral and can be paid back in flexible installments.</span>

      </span>
    ),
    index: 1
  },
  {
    heading: "How does Kuda Salary Loan work?",
    list: (
      <span className="flex flex-column">
        <span className="pt-3 career-message-margin-bottom ">
          <div className="flex flex-column  leading-md-6 f-md-16">
            <div className="mb-3">1. Refer your employer  <a
              href="/en-ng/salary-loan-retail-form/"
              target="_blank"
              rel="noopener noreferrer"
              className="color-primary link-decoration cursor-pointer ml-1"
            >here.</a></div>

            <div className="mb-3">2. If your employer signs you up to access Kuda Salary Loan, you can download the Kuda app, open a Kuda account with your BVN and a valid ID, then apply for a loan.</div>
            <div className="mb-3">3. Once our quick automated loan approval process is completed, we’ll send you a loan offer on your Kuda app.</div>
            <div className="mb-3">4. If you accept our loan offer, we’ll send the money to your Kuda account in a few minutes.</div>
            <div className="mb-3">5. Loan repayments will be deducted automatically from your salary by your employer.</div>
          </div>
        </span>
      </span>),
    index: 2
  },
  {
    heading: "How much can you borrow?",
    list: (
      <span className="flex flex-column f-16"><span>You can borrow up to 30% of their annual salary for up to six (6) months.
      </span>
      </span>),
    index: 3
  },
  {
    heading: <div className="question-title-max">
      How does your employer get access to Kuda Salary Loan?
    </div>,
    list: (
      <span className="flex flex-column">
        <span className="faq-description f-16">To get access to Kuda Salary Loan, your employer will need to apply <a
          href="/en-ng/salary-loan-retail-form/"
          target="_blank"
          rel="noopener noreferrer"
          className="color-primary link-decoration cursor-pointer ml-1"
        >here.</a> If we accept the application, our support team will guide your employer through the loan setup process.</span>

      </span>

    ),
    index: 4
  },

]

const moreForYou = [

  {
    icon: <KudaSaveIcon />,
    title: "Save",
    subText: `Make saving easier with Spend+Save, Pocket or Fixed savings with up to 15% annual interest.`,
    linkTo: `/en-ng/save/`
  },
  {
    icon: <OverdraftIcon />,
    title: "Overdrafts",
    subText: "Spend above your account balance to meet your urgent needs and pay back at just 0.3% daily interest.",
    linkTo: `/en-ng/overdrafts/`
  },

  {
    icon: <BorrowIcon />,
    title: "Loans",
    subText: "Get up to ₦150,000 in your Kuda account easily and repay in convenient instalments.",
    linkTo: `/en-ng/personal-loan/`
  },
]

const SalaryLoanRetail = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <BadgesEntryFold
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        illustration={entryContent.illustration}
        headerLink={entryContent.url}
        btnTitle={entryContent.name}
        showEmployerBtn={true}
      />
      <BelowFoldCards topFeatures={kudaTopInvestments} />
      <ImageTextRight
        title={interestRate.title}
        subtitle={interestRate.subtitle}
        illustration={interestRate.illustration}
        name={interestRate.name}
        url={interestRate.url}
        isExternal={true}
      />
      <ImageTextLeft
        title={quickLoan.title}
        subtitle={quickLoan.subtitle}
        illustration={quickLoan.illustration}
        name={quickLoan.name}
        url={quickLoan.url}
        isExternal={true}
      />

      <ImageTextRight
        title={repayLoan.title}
        subtitle={repayLoan.subtitle}
        illustration={repayLoan.illustration}
        name={repayLoan.name}
        url={repayLoan.url}
        isExternal={true}
      />
      <FAQList title={"Salary Loan FAQs"} questions={questions} />

<MoreForYouComponent moreForYou={moreForYou} />
      <CTAInvestments />
    </Fragment>
  )
}

export default SalaryLoanRetail